import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import './styles.scss'

/**
 * @component Form
 * @props - { object } -  config
 */
class enForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mailSent: false,
      error: null,
      mailValid: false,
      msgValid: false,
    }
  }
  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  handleFormSubmit = e => {
    const { msgErrorMessage, mailErrorMessage } = this.props.config
    if (this.state.mailValid && this.state.msgValid) {
      e.preventDefault()
      axios({
        method: 'Post',
        url: `https://roessler-lee.com/api/contact/index.php`,
        headers: { 'Content-type': 'application/json' },
        data: this.state,
      })
        .then(result => {
          if (result.data.sent) {
            this.setState({
              mailSent: result.data.sent,
            })
            this.setState({ error: false })
          } else {
            this.setState({ error: true })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({ error: error.message })
        })
    } else {
      if (!this.state.msgValid) {
        this.setState({ error: msgErrorMessage })
      } else if (!this.state.mailVaid) {
        this.setState({ error: mailErrorMessage })
      }
    }
  }
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - namve of the field
   * @return void
   */
  handleChange = (e, field) => {
    let value = e.target.value

    if (field == 'email') {
      if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null) {
        this.setState({
          mailValid: true,
        })
      } else {
        this.setState({
          mailValid: false,
        })
      }
    }

    if (field == 'msg') {
      if (value.length > 0) {
        this.setState({
          msgValid: true,
        })
      } else {
        this.setState({
          msgValid: false,
        })
      }
    }

    let updateValue = {}
    updateValue[field] = value
    this.setState(updateValue)
  }

  render() {
    const {
      title,
      buttonValue,
      successMessage,
      errorMessage,
      fieldsConfig,
      msgErrorMessage,
    } = this.props.config
    return (
      <div>
        <div>
          <form action="#">
            <div>
              {this.state.mailSent && (
                <div className="alert alert-success alert-dismissible fade show">
                  {successMessage}
                </div>
              )}
            </div>
            <div>
              {this.state.error && (
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  {this.state.error}
                </div>
              )}
            </div>
            <div className="controls">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    {fieldsConfig &&
                      fieldsConfig.map(field => {
                        return (
                          <div key={field.id} className="form-group">
                            <React.Fragment key={field.id}>
                              {field.type !== 'textarea' ? (
                                <React.Fragment>
                                  <label>{field.label}</label>
                                  <input
                                    type={field.type}
                                    className={field.klassName}
                                    placeholder={field.placeholder}
                                    value={field.name}
                                    data-error={field.dataError}
                                    onChange={e =>
                                      this.handleChange(e, field.fieldName)
                                    }
                                  />
                                  <div className="help-block with-errors"></div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <label>{field.label}</label>
                                  <textarea
                                    className={field.klassName}
                                    placeholder={field.placeholder}
                                    onChange={e =>
                                      this.handleChange(e, field.fieldName)
                                    }
                                    value={field.name}
                                    data-error={field.dataError}
                                  />
                                  <div className="help-block with-errors"></div>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          </div>
                        )
                      })}
                    <div className="contact-buttons float-right">
                      <input
                        type="submit"
                        onClick={e => this.handleFormSubmit(e)}
                        value={buttonValue}
                        className="btn btn-submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default enForm
//propTypes for the component
enForm.propTypes = {
  config: PropTypes.object.isRequired,
}

/*
axios({
      method: 'post',
      url: `http://s799268893.online.de/api/contact/index.php`,
      headers: { 'Content-type': 'application/json' },
      data: this.state,
    })
    */
